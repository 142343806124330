// Footer.js

import React from 'react';
import "./Headerfooter.component.css"


const Foot_bar = () => {
  
  return (
    <div className="footer pg-4">
      <p>&copy; 2023 100Threads. All rights reserved.</p>
    </div>
  );
}

export default Foot_bar;
